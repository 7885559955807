<script setup>
/* eslint-disable no-unused-vars */
import { onMounted, ref, reactive } from "vue";
import * as reportsAPI from "@/http/reports";
import { ElNotification } from "element-plus";
import moment from "@/helpers/moment";
import { Search } from "@element-plus/icons-vue";
import ClientModal from "../clients/ClientModal.vue";

const openDialog = ref(false);
const clientData = ref();
const loading = ref(false);
const tableData = ref([]);
const paginationData = reactive({
  lastPage: "",
  from: "",
  to: "",
  total: "",
  page: 1,
  perPage: 10,
  currentPage: "",
});

const requestQuery = reactive({
  processed: null,
  archived: null,
  description: null,
  user_id: null,
});

onMounted(() => {
  fetchData();
});

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await reportsAPI.getReports(
      paginationData.page,
      paginationData.perPage,
      requestQuery
    );
    tableData.value = response.data;
    paginationData.lastPage = response.last_page;
    paginationData.from = response.from;
    paginationData.to = response.to;
    paginationData.total = response.total;
    paginationData.currentPage = response.current_page;
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Oops. Les signalements n'ont pas pu être chargés",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
};

const handleArchive = async (id) => {
  try {
    loading.value = true;
    await reportsAPI.archiveReport(id);
    fetchData();
    ElNotification({
      title: "Succès",
      message: "Signalement archivé !",
      type: "success",
    });
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Veuillez réessayer",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
};

const handleDetailsModal = (item) => {
  clientData.value = item;
  openDialog.value = true;
};

const handleProcess = async (id) => {
  try {
    loading.value = true;
    await reportsAPI.processReport(id);
    fetchData();
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Veuillez réessayer",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <!-- Breadcrumb -->
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-uppercase">
          <a href="#">Findlove</a>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Signalements ({{ paginationData?.total }})
        </li>
      </ol>
    </nav>
    <el-select
      v-model="requestQuery.archived"
      @change="fetchData()"
      class="w-50 m-2"
      placeholder="Rechercher..."
      :prefix-icon="Search"
      size="large"
    >
      <el-option :value="null" label="Tous" />
      <el-option :value="true" label="Archivé" />
      <el-option :value="false" label="Non Archivé" />
    </el-select>
  </div>
  <hr class="mb-5" />
  <!-- End Breadcrumb -->
  <div class="card py-3 border-0 table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Emis par</th>
          <th scope="col">Sur</th>
          <!-- <th scope="col">Statut</th> -->
          <th scope="col">Description</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in tableData" :key="index">
          <tr>
            <td>
              {{ moment(item.created_at).format("Do MMMM YYYY à HH:mm") }}
            </td>
            <td>
              <span
                :class="[
                  item?.user?.certified
                    ? 'bg-success rounded px-2 text-white'
                    : '',
                ]"
                class="pointer"
                @click="handleDetailsModal(item?.user)"
              >
                {{ item?.user?.name }}
              </span>
              <br />
              <small
                >Signalement(s):
                {{ item?.user?.reports_received.length }}</small
              >
            </td>
            <td>
              <span
                :class="[
                  item?.reported_user?.certified
                    ? 'bg-success rounded px-2 text-white'
                    : '',
                ]"
                class="pointer"
                @click="handleDetailsModal(item?.reported_user)"
              >
                {{ item?.reported_user?.name }}
              </span>
              <br />
              <small
                >Signalement(s):
                {{ item?.reported_user?.reports_received.length }}
              </small>
            </td>
            <!--  <td>
              <div
                v-if="item?.processed"
                class="bg-danger rounded px-2 text-white"
              >
                Bloqué
              </div>
              <div
                v-if="item?.archived"
                class="bg-warning rounded px-2 mt-2 text-white"
              >
                Archivé
              </div>
            </td> -->
            <td>{{ item?.description }}</td>
            <td>
              <div v-if="item?.processed === false && item?.archived === false">
                <el-popconfirm
                  confirm-button-text="Oui"
                  cancel-button-text="Non"
                  icon-color="red"
                  title="Êtes-vous sûr de vouloir archiver ce signalement ?"
                  @confirm="handleArchive(item?.id)"
                >
                  <template #reference>
                    <el-button class="m-2" type="warning" size="small">
                      Archiver
                    </el-button>
                  </template>
                </el-popconfirm>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <Loader :loading="loading" />
    <div class="d-flex justify-content-between align-items-center m-3">
      <ul class="pagination pointer">
        <li>
          <p
            @click="
              () => {
                if (paginationData.page > 1) {
                  paginationData.page--;
                  fetchData();
                }
              }
            "
            aria-label="Previous"
          >
            <span aria-hidden="true">«</span>
          </p>
        </li>
        <template v-for="(page, index) in paginationData.lastPage" :key="index">
          <li :class="{ active: page === paginationData.currentPage }">
            <p
              @click="
                page !== paginationData.currentPage &&
                  ((paginationData.page = page), fetchData())
              "
            >
              {{ page }}
            </p>
          </li>
        </template>
        <li>
          <p
            @click="
              () => {
                if (paginationData.page < lastPage) {
                  paginationData.page++;
                  fetchData();
                }
              }
            "
            aria-label="Next"
          >
            <span aria-hidden="true">»</span>
          </p>
        </li>
      </ul>
      <div class="">
        <em
          >Affichage de {{ paginationData.from }} à {{ paginationData.to }} (sur
          {{ paginationData.total }} éléments)</em
        >
      </div>
    </div>
    <ClientModal
      v-model:open="openDialog"
      :clientData="clientData"
      :certified="null"
      @onClose="(cb) => (cb ? fetchData() : null)"
    />
  </div>
</template>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
}

.label {
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 5px;
  background: rgba(38, 150, 190, 0.2);
  color: rgb(38, 150, 190);
}
</style>
