import request from "./request";

export function getReports(page, perPage, filters) {
  let url = `dashboard/reports?page=${page}&per_page=${perPage}`;
  Object.keys(filters).forEach(key => {
    if (filters[key] !== null) {
      url += `&filter[${key}]=${filters[key]}`;
    }
  });
  return request({
    url,
    method: "get",
  });
}

export function processReport(id) {
  return request({
    url: `dashboard/reports/${id}/process`,
    method: "post",
  });
}

export function archiveReport(id) {
  return request({
    url: `dashboard/reports/${id}/archive`,
    method: "post",
  });
}
